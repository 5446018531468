const baseUrl = 'rest/';
const serviceName = 'client_HomePageService';

export default (build) => ({
  getpracticallist: build.query({
    query: () => ({
      url: `/v1/edu/tp/list`,
      method: 'get',
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
  // 实训项目详情页接口
  getpracticaldetails: build.query({
    query: (id) => ({
      url: `/v1/edu/tp/detail?TpId=${id}`,
      method: 'get',
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
});
