import axios from 'axios';

const axiosBaseQuery =
  ({ baseURL } = { baseURL: '' }) =>
  async (axiosOptions, { dispatch, getState }) => {
    try {
      // const token = getState().auth.token;
      // const locale = getState().common.locale;
      const { Header = {}, isPublic = false, ...restOptions } = axiosOptions || {};
      // Header['accept-language'] = '1' || 'zh';
      // if (token && !restOptions?.auth && !headers?.authorization && !isPublic) {
      //   headers.authorization = `Bearer ${token}`;
      // }
      const request = {
        baseURL, // 设置 baseURL
        withCredentials: false,
        Header,
      };
      if (axiosOptions?.signal) {
        request.signal = axiosOptions?.signal;
      }
      const response = await axios({
        ...request,
        ...restOptions,
      });
      return { data: response?.data };
    } catch (error) {
      return { error };
    }
  };
export default axiosBaseQuery;
