import React, { Suspense } from 'react';
import { useRoutes } from 'react-router-dom';
import { useSelector } from 'react-redux';
import SuspenseFallback from 'components/spinner/SuspenseFallback';
import { ConfigProvider } from 'antd';
import enUS from 'antd/locale/en_US';
import AppLayout from 'components/layout/AppLayout';

import { IntlProvider } from 'react-intl';
import { loadLocale } from 'languages/index';

import routes from './routes';

const App = () => {
  const app = useRoutes(routes);
  const { locale } = useSelector((state) => state.auth);
  const { message } = loadLocale(locale);

  return (
    <ConfigProvider
      locale={enUS}
      theme={{
        token: {
          // Seed Token，影响范围大
          colorPrimary: '#1677ff',
          borderRadius: 2,
          // 派生变量，影响范围小
        },
      }}
    >
      <IntlProvider locale={locale} messages={message}>
        <AppLayout loading={false}>
          <Suspense fallback={<SuspenseFallback />}>{app}</Suspense>
        </AppLayout>
      </IntlProvider>
    </ConfigProvider>
  );
};

export default App;
