import React from 'react';

const NotFound = React.lazy(() => import('components/exception/NotFound'));

const Home = React.lazy(() => import('features/showpage/home/Home'));
const Hotcourse = React.lazy(() => import('features/showpage/hotcourse/Hotcourse'));
const HotcourseDetail = React.lazy(() => import('features/showpage/hotcourse/HotcourseDetail'));
const TutorlectureDetail = React.lazy(() => import('features/showpage/tutorlecture/TutorlectureDetail'));
const PracticalDetail = React.lazy(() => import('features/showpage/practical/PracticalDetail'));
const ResourceDetail = React.lazy(() => import('features/showpage/resource/ResourceDetail'));
const Purchase = React.lazy(() => import('features/showpage/purchase/Purchase'));

const Tutorlecture = React.lazy(() => import('features/showpage/tutorlecture/Tutorlecture'));
const Practical = React.lazy(() => import('features/showpage/practical/Practical'));
const Resource = React.lazy(() => import('features/showpage/resource/Resource'));
const Login = React.lazy(() => import('features/showpage/login/Login'));
const Personal = React.lazy(() => import('features/showpage/personal/Personal'));

// admin 页面
const Admin = React.lazy(() => import('features/admin/Admin'));
const HotcourseList = React.lazy(() => import('features/admin/hotcourseList/HotcourseList'));
const HotcourseNew = React.lazy(() => import('features/admin/hotcourseList/HotcourseNew'));
const HotcourseAlter = React.lazy(() => import('features/admin/hotcourseList/HotcourseAlter'));

const PracticalList = React.lazy(() => import('features/admin/practicalList/PracticalList'));
const ResourceLIst = React.lazy(() => import('features/admin/resourceList/ResourceLIst'));
const TutorlectureList = React.lazy(() => import('features/admin/tutorlectureList/TutorlectureList'));

const routes = [
  {
    path: '/',
    element: <Home />,
  },
  {
    path: 'admin',
    element: <Admin />,
    children: [
      {
        path: 'hotcourseList',
        children: [
          { path: '', index: true, element: <HotcourseList /> },
          { path: 'new', index: true, element: <HotcourseNew /> },
          { path: ':id', index: true, element: <HotcourseAlter /> },
        ],
      },
      { path: 'practicalList', element: <PracticalList /> },
      { path: 'resourceLIst', element: <ResourceLIst /> },
      { path: 'tutorlectureList', element: <TutorlectureList /> },
    ],
  },
  {
    path: '/home',
    element: <Home />,
  },
  {
    path: '/login',
    element: <Login />,
  },
  {
    path: '/personal',
    element: <Personal />,
  },
  {
    path: '/purchase',
    element: <Purchase />,
  },
  {
    path: 'hotcourse',
    children: [
      { path: '', index: true, element: <Hotcourse /> },
      { path: ':id', index: true, element: <HotcourseDetail /> },
    ],
  },
  {
    path: 'tutorlecture',
    children: [
      { path: '', index: true, element: <Tutorlecture /> },
      { path: ':id', index: true, element: <TutorlectureDetail /> },
    ],
  },
  {
    path: 'practical',
    children: [
      { path: '', index: true, element: <Practical /> },
      { path: ':id', index: true, element: <PracticalDetail /> },
    ],
  },
  {
    path: 'resource',
    children: [
      { path: '', index: true, element: <Resource /> },
      { path: ':id', index: true, element: <ResourceDetail /> },
    ],
  },
  { path: '*', element: <NotFound /> },
];

export default routes;
