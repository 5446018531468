/* eslint-disable array-callback-return */
import React, { useState, useEffect } from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { Layout, Menu, Dropdown, Button, Avatar, Drawer, Select, Row, Col } from 'antd';
import { UserOutlined, AntDesignOutlined, MenuOutlined } from '@ant-design/icons';
import { authActions } from 'store/authSlice';
import { useSelector, useDispatch } from 'react-redux';
import { enquireScreen } from 'enquire-js';

import { FormattedMessage } from 'react-intl';

const { Header } = Layout;

export default function GlobalAppBar({ changeColorOnScroll }) {
  const [menuMode, setMenuMode] = useState('horizontal');
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  const { token, locale } = useSelector((state) => state.auth);
  const navigation = useNavigate();
  const location = useLocation();

  const startIndex = location.pathname.indexOf('/') + 1;
  const endIndex = location.pathname.indexOf('/', startIndex);
  const result = location.pathname.substring(startIndex, endIndex);
  const result1 = location.pathname.substring(startIndex);

  useEffect(() => {
    enquireScreen((b) => {
      setMenuMode(b ? 'inline' : 'horizontal');
    });
  }, []);

  const headerColorChange = () => {
    const windowsScrollTop = window.pageYOffset;
    if (windowsScrollTop > changeColorOnScroll.height) {
      // 修改 header 颜色
    } else {
      // 恢复 header 颜色
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };

  const onClose = () => {
    setOpen(false);
  };

  useEffect(() => {
    window.removeEventListener('scroll', headerColorChange);
    if (changeColorOnScroll) {
      window.addEventListener('scroll', headerColorChange);
    }
    return function cleanup() {
      if (changeColorOnScroll) {
        window.removeEventListener('scroll', headerColorChange);
      }
    };
  }, [changeColorOnScroll]);

  const handlquit = () => {
    dispatch(authActions.clearCredentials());
  };

  const items = [
    {
      key: '1',
      label: (
        <Button type="link" onClick={() => navigation('/personal')}>
          我的课程
        </Button>
      ),
    },
    {
      key: '2',
      label: (
        <Button type="link" onClick={() => navigation('/personal')}>
          我的订单
        </Button>
      ),
    },
    {
      key: '3',
      label: (
        <Button type="link" onClick={() => navigation('/personal')}>
          个人信息
        </Button>
      ),
    },
    {
      key: '4',
      label: <Button onClick={() => handlquit()}>安全退出</Button>,
    },
  ];

  const menuitems = [
    {
      key: '1',
      children: undefined,
      label: <FormattedMessage id="home" />,
      type: undefined,
    },
    {
      key: '2',
      children: undefined,
      label: <FormattedMessage id="popularcourse" />,
      type: undefined,
    },
    {
      key: '3',
      children: undefined,
      label: <FormattedMessage id="tutorlecture" />,
      type: undefined,
    },
    {
      key: '4',
      children: undefined,
      label: <FormattedMessage id="internshipproject" />,
      type: undefined,
    },
    {
      key: '5',
      children: undefined,
      label: <FormattedMessage id="resourcecenter" />,
      type: undefined,
    },
  ];

  const handleChange = (e) => {
    dispatch(authActions.setLocale(e));
  };

  return (
    <>
      <Header
        style={{
          boxShadow: '10px 2px 8px rgba(0, 0, 0, 0.15)',
          backgroundColor: 'rgba(0, 0, 0, 0.7)',
          width: '100%',
          padding: '0 20px',
          // textAlign: 'center',
          display: 'flex',
          // justifyContent: 'center',
          position: 'fixed',
          top: 0,
          left: 0,
          right: 0,
          zIndex: 1000,
        }}
      >
        {menuMode === 'horizontal' ? (
          <>
            <Link to="/">
              <Avatar
                src="https://www.lanboai.cn/_nuxt/img/logo.aa3291c.svg"
                size={{ xs: 50, sm: 50, md: 50, lg: 50, xl: 50, xxl: 50 }}
                icon={<AntDesignOutlined />}
              />
            </Link>
            <Menu
              mode="horizontal"
              style={{ width: '70%', backgroundColor: 'transparent', color: '#fff', fontSize: '30' }}
              defaultSelectedKeys={[endIndex > 1 ? result : result1]}
            >
              <Menu.Item key="home">
                <Link to="/home">
                  <FormattedMessage id="home" />
                </Link>
              </Menu.Item>
              <Menu.Item key="hotcourse">
                <Link to="/hotcourse">
                  <FormattedMessage id="popularcourse" />
                </Link>
              </Menu.Item>
              <Menu.Item key="tutorlecture">
                <Link to="/tutorlecture">
                  <FormattedMessage id="tutorlecture" />
                </Link>
              </Menu.Item>
              <Menu.Item key="practical">
                <Link to="/practical">
                  <FormattedMessage id="internshipproject" />
                </Link>
              </Menu.Item>
              <Menu.Item key="resource">
                <Link to="/resource">
                  <FormattedMessage id="resourcecenter" />
                </Link>
              </Menu.Item>
            </Menu>
            <div style={{ width: '30%', textAlign: 'right' }}>
              {/* <Select
                defaultValue={locale}
                bordered
                style={{ width: 90, marginTop: '15px', marginRight: '20px' }}
                onChange={handleChange}
                options={[
                  { value: 'zh-CN', label: <FormattedMessage id="chinese" /> },
                  { value: 'en-US', label: <FormattedMessage id="english" /> },
                ]}
              /> */}
              {token !== null ? (
                <Dropdown menu={{ items }} placement="bottomLeft">
                  {/* <Avatar
                    onClick={() => (token !== '' ? '' : navigation('/login'))}
                    style={{ cursor: 'pointer' }}
                    size="large"
                  >
                    {token !== null ? <UserOutlined /> : '登陆'}
                  </Avatar> */}
                  <Button type="primary" onClick={() => (token !== '' ? '' : navigation('/login'))}>
                    {token !== null ? <UserOutlined /> : '登陆'}
                  </Button>
                </Dropdown>
              ) : (
                // <Avatar onClick={() => navigation('/login')} style={{ cursor: 'pointer' }} size="large">
                //   {token !== null ? <UserOutlined /> : '登陆'}

                // </Avatar>
                <Button type="primary" onClick={() => navigation('/login')}>
                  {token !== null ? <UserOutlined /> : '登陆'}
                </Button>
              )}
            </div>
          </>
        ) : (
          <div style={{ width: '100%' }}>
            <Row style={{ width: '100%' }}>
              <Col span={22}>
                <Link to="/">
                  <Avatar
                    src="https://www.lanboai.cn/_nuxt/img/logo.aa3291c.svg"
                    size={{ xs: 50, sm: 50, md: 50, lg: 50, xl: 50, xxl: 50 }}
                    icon={<AntDesignOutlined />}
                  />
                </Link>
              </Col>
              <Col span={2}>
                <Button
                  style={{
                    fontSize: '16px',
                    width: 64,
                    height: 64,
                    color: '#fff',
                  }}
                  type="text"
                  icon={<MenuOutlined />}
                  onClick={showDrawer}
                />
              </Col>
            </Row>

            <Drawer
              bodyStyle={{ padding: '0' }}
              width="200"
              closeIcon={false}
              placement="right"
              onClose={onClose}
              open={open}
            >
              <Menu
                style={{ width: '100%' }}
                // mode="horizontal"
                defaultSelectedKeys={['1']}
                defaultOpenKeys={['sub1']}
                // mode={mode}
                // theme={theme}
                items={menuitems}
              />
            </Drawer>
          </div>
        )}
      </Header>
      {/* 如何要考虑屏幕分辨率大小的情况下,导航栏的展示则需要修改以下代码 */}
    </>
  );
}
