import React from 'react';
import { useLocation } from 'react-router-dom';

import GlobalFooter from 'components/footer/GlobalFooter';
import GlobalAppBar from 'components/header/GlobalAppBar';
import Header from 'components/header/Header';

const AppLayout = ({ loading, menu, theme, children }) => {
  const location = useLocation();
  if (location.pathname.slice(0, 6) === '/admin') {
    return loading ? (
      // 根据接口状态展示loading状态
      <div>loading。。。</div>
    ) : (
      <div style={{ minHeight: '100vh', display: 'flex', flexDirection: 'column' }}>
        <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column' }}>{children}</div>
      </div>
    );
  }
  return loading ? (
    // 根据接口状态展示loading状态
    <div>loading。。。</div>
  ) : (
    <div
      style={{
        minHeight: '100vh',
        display: 'flex',
        flexDirection: 'column',
        background: '#f6f8fb',
        overflow: 'hidden',
        overflowY: 'scroll',
      }}
    >
      <GlobalAppBar />
      {/* <Header /> */}
      <div style={{ flexGrow: 1, display: 'flex', flexDirection: 'column', minHeight: '1000px' }}>{children}</div>
      <GlobalFooter />
    </div>
  );
};

export default AppLayout;
