import zhCN from './zh-cn/index';
import enUS from './en-us/index';

function loadLocale(lang) {
  let locale = null;
  let message = null;
  let antLocale = null;
  switch (lang) {
    case 'en-US':
      locale = 'en-US';
      message = enUS;
      antLocale = enUS;
      break;
    case 'zh-CN':
      locale = 'zh-CN';
      message = zhCN;
      antLocale = zhCN;
      break;
    default:
      locale = 'zh-CN';
      message = zhCN;
      antLocale = zhCN;
      break;
  }
  return { locale, message, antLocale };
}
export { loadLocale };
