const baseUrl = 'rest/';
const serviceName = 'client_HomePageService';

export default (build) => ({
  // 登陆接口
  getlogin: build.mutation({
    query: (data) => ({
      url: `/v1/edu/user/login/pwd`,
      method: 'post',
      data,
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
  // 获取用户信息
  getuserinfo: build.query({
    query: (headers) => ({
      url: `/v1/edu/user/userinfo`,
      method: 'get',
      headers: {
        token: headers,
      },
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
  // 注册接口
  getregister: build.mutation({
    query: (data) => ({
      url: `/v1/edu/user/register`,
      method: 'post',
      data,
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
  // 点击发送邮箱
  sendingmailbox: build.mutation({
    query: (data) => ({
      url: `/v1/edu/user/sendcode/email?email=${data}`,
      method: 'get',
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
});
