import { configureStore } from '@reduxjs/toolkit';

import api from 'services/api';
import homeReducer from './homeSlice';
import authReducer from './authSlice';

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    home: homeReducer,
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false, // 处理非序列化数据校验
    }).concat(api.middleware),
});
