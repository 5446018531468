import { createSlice } from '@reduxjs/toolkit';

import config from 'services/config';

const tokenStorageKey = config.tokenStorageKey;

const initialState = {
  initialized: false,
  authorized: false,
  token: config.storage.getItem(tokenStorageKey),
  user: null,
  isNewUser: false,
  showOnboardingModal: false,
  flag: true,
  userinfo: {
    phone: '',
    email: '',
    username: '',
  },
  locale: 'zh-CN',
};

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    setToken: (state, action) => {
      state.token = action.payload;
    },

    setAccount: (state, action) => {
      state.user = action.payload;
      state.initialized = true;
      state.authorized = true;
      state.authModal = null;
    },

    setLocale: (state, action) => {
      state.locale = action.payload;
    },
    setuserinfo: (state, action) => {
      state.userinfo = action.payload;
      localStorage.setItem('userinfo', JSON.stringify(action.payload));
    },
    resetAccount: (state) => {
      state.token = null;
      state.authorized = false;
      state.user = null;
    },
    setCredentials: (state, action) => {
      localStorage.setItem(tokenStorageKey, action.payload);
      state.token = action.payload;
    },
    clearCredentials: (state) => {
      localStorage.removeItem(tokenStorageKey);
      state.token = null;
      state.authorized = false;
      state.user = null;
    },
    sidebarFlag: (state, action) => {
      state.flag = action.payload;
    },
  },
});

export const authActions = {
  ...authSlice.actions,
};

export default authSlice.reducer;
