const home = {
  home: '首页',
  popularcourse: '热门课程',
  tutorlecture: '导师讲座',
  internshipproject: '实训项目',
  resourcecenter: '资源中心',
  chinese: '🇨🇳 中文',
  english: '🇬🇧 英文',
  carouselTitleOne: '百万年薪算法工程师',
  carouselTitleTwo: '深兰科技 实战项目',
  carouselTitleThree: '世界顶级算力教学支持',
};
export default home;
