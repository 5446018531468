const baseUrl = 'rest/';
const serviceName = 'client_HomePageService';

export default (build) => ({
  getcourselist: build.query({
    query: () => ({
      url: `/v1/edu/course/list`,
      method: 'get',
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
  // 热门课程详情页接口
  getcoursedetails: build.query({
    query: (id) => ({
      url: `/v1/edu/course/detail?CourseId=${id}`,
      method: 'get',
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
});
