import React from 'react';
import { LoadingOutlined } from '@ant-design/icons';
import { createUseStyles } from 'react-jss';
import { Spin } from 'antd';

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const useStyles = createUseStyles({
  SuspenseFallback: {
    minHeight: 256,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const SuspenseFallback = () => {
  const classes = useStyles();
  return (
    <div className={classes.SuspenseFallback}>
      <Spin indicator={antIcon} />
    </div>
  );
};

export default SuspenseFallback;
