import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Menu, Row, Col, Button, Popover, Badge } from 'antd';
import { MenuOutlined } from '@ant-design/icons';

const searchEngine = 'Google';

const Header = ({ isFirstScreen, isMoblie }) => {
  const [menuVisible, setMenuVisible] = useState(false);

  const onMenuVisibleChange = (visible) => {
    setMenuVisible(visible);
  };

  const handleShowMenu = () => {
    setMenuVisible(true);
  };

  const handleHideMenu = () => {
    setMenuVisible(false);
  };

  const handleSelectFilter = (value, option) => {
    const optionValue = option.props['data-label'];
    return optionValue === searchEngine || optionValue.indexOf(value.toLowerCase()) > -1;
  };

  const menuMode = isMoblie ? 'inline' : 'horizontal';
  const headerClassName = classNames({
    clearfix: true,
    'home-nav-white': !isFirstScreen,
  });

  const menu = (
    <>
      {/* <Button className="header-lang-button" ghost size="small" key="lang">
        English
      </Button> */}
      <Menu mode={menuMode} defaultSelectedKeys={['home']} id="nav" key="nav">
        <Menu.Item key="home">首页</Menu.Item>
        <Menu.Item key="docs/spec">指引</Menu.Item>
        <Menu.Item key="docs/react">组件</Menu.Item>
        <Menu.Item key="docs/pattern">模式</Menu.Item>
        <Menu.Item key="docs/resource">资源</Menu.Item>
        <Menu.Item key="pro">
          <a href="http://pro.ant.design" className="header-link" target="_blank" rel="noopener noreferrer">
            PRO
            <span
              style={{
                display: 'inline-block',
                position: 'relative',
                top: -2,
                width: 18,
              }}
            >
              <Badge dot />
            </span>
          </a>
        </Menu.Item>
      </Menu>
    </>
  );

  return (
    <header id="header" className={headerClassName}>
      {menuMode === 'inline' && (
        <Popover
          overlayClassName="popover-menu"
          placement="bottomRight"
          content={menu}
          trigger="click"
          visible={menuVisible}
          arrowPointAtCenter
          onVisibleChange={onMenuVisibleChange}
        >
          <MenuOutlined className="nav-phone-icon" onClick={handleShowMenu} />
        </Popover>
      )}
      <Row>
        <Col lg={20} md={19} sm={0} xs={0}>
          {menuMode === 'horizontal' && menu}
        </Col>
      </Row>
    </header>
  );
};

export default Header;
