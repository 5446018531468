import React from 'react';
import { Divider } from 'antd';
import { createUseStyles } from 'react-jss';

const useStyles = createUseStyles({
  layoutStyle: {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    background: '#333333',
  },
  contentstyle: {
    marginTop: '20px',
    width: '1200px',
    color: '#898989',
    fontSize: '12px',
  },
});

export default function GlobalFooter() {
  const classes = useStyles();

  return (
    <div className={classes.layoutStyle}>
      <div className={classes.contentstyle}>
        <h2 style={{ marginBottom: '10px', color: '#fff' }}>关于我们</h2>
        <div>
          深兰启承企业管理（上海）有限公司（以下简称启承），与深兰科技联合合作深兰教育品牌，主要从事教育类数字化产品的开发与应用，利用先进的技术手段，提高教育质量和效率，满足不同学习者的需求，促进教育公平和创新。
          <br />
          <br />
          深兰科技创立于2014年，是快速成长的人工智能领先企业，作为AI产业生态制造者，公司深度布局智能驾驶、智能机器人、智能工业、智能环卫、AI元宇宙和智能大健康等领域，在上海、北京、深圳、武汉和济南等多个地方设立研发中心。
          <br />
          <br />
          深兰科技以“人工智能
          服务民生”为理念，致力于人工智能基础研究和应用开发。依托自主知识产权的计算机视觉、生物智能、自动驾驶、认知智能等核心技术，在CVPR、ICCV、ECCV、ACL、NAACL、KDD、NeurIPS及AAAI等众多世界计算机科学及人工智能领域顶级赛事上获得40余项冠军，被国家工信部授予专精特新“小巨人”企业称号。
          <br />
          <br />
          此外，深兰科技获批设立院士专家工作站，与上海交通大学、中南大学等多个国内外知名院校，建立包括人工智能、自动驾驶、AI芯片、5G应用与计算机视觉等多个相关领域的联合实验室，与深兰科学院共同构筑了深兰特色的科研体系。
          <br />
          <br />
          深兰科技和启承联合创办人工智能人才培养项目，响应国家人工智能龙头企业开展职业教育和产教融合的政策，在深兰企业内部授课学习，而启承作为教育主体，旨在培养全能的、高端的人工智能相关的复合型人才。
        </div>
        <Divider style={{ background: '#fff' }} />
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <div style={{ display: 'flex', alignItems: 'center', width: '500px' }}>
            <img
              src="	https://www.lanboai.cn/_nuxt/img/footer-logo.4091e5e.png"
              alt="Grapefruit slice atop a pile of other slices"
            />
            <div style={{ marginLeft: '10px' }}>
              <h1>深兰教育</h1>
              <p>我的实验课堂</p>
            </div>
          </div>
          <div style={{ textAlign: 'center', paddingTop: '30px' }}>
            <div>
              <span>帮助</span>

              <span>隐私</span>

              <span>条款</span>
            </div>
            <p>@2022 深兰启承企业管理（上海）有限公司 版权所有</p>
          </div>
        </div>
      </div>
    </div>
  );
}
