const baseUrl = 'rest/';
const serviceName = 'client_HomePageService';

export default (build) => ({
  gettutorlecturelist: build.query({
    query: () => ({
      url: `/v1/edu/jiangzuo/list`,
      method: 'get',
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
  // 讲座详情页接口
  gettutorlecturedetails: build.query({
    query: (id) => ({
      url: `/v1/edu/jiangzuo/detail?JiangzuoId=${id}`,
      method: 'get',
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
});
