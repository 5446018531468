import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  list: [],
  navkey: '',
};

export const homeSlice = createSlice({
  name: 'home',
  initialState,
  reducers: {
    setNavkey: (state, action) => {
      state.navkey = action.payload;
    },
  },
});

export const homeActions = {
  ...homeSlice.actions,
};

export default homeSlice.reducer;
