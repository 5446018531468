const home = {
  home: 'Home',
  popularcourse: 'Popular course',
  tutorlecture: 'Tutor lecture',
  internshipproject: 'Internship project',
  resourcecenter: 'Resource center',
  chinese: '🇨🇳 Chinese',
  english: '🇬🇧 English',
  carouselTitleOne: 'Million Dollar Algorithm Engineer',
  carouselTitleTwo: 'Shenlan technology practical project',
  carouselTitleThree: 'World Class Arithmetic Teaching Support',
};
export default home;
