import { createApi } from '@reduxjs/toolkit/query/react';

import axiosBaseQuery from './axiosBaseQuery';
import home from './home';
import config from './config';
import hotcourse from './hotcourse';
import tutorlecture from './tutorlecture';
import practical from './practical';
import resource from './resource';

import login from './login';

const api = createApi({
  reducerPath: 'api',
  baseQuery: axiosBaseQuery({
    baseURL: '',
    headers: {
      token: '111',
    },
  }),
  tagTypes: [],
  endpoints: (build) => ({
    getUserInfo: build.query({
      query: (token) => ({
        url: 'rest/userInfo',
        method: 'GET',
        headers: { authorization: `Bearer` },
      }),
    }),
    ...home(build),
    ...hotcourse(build),
    ...tutorlecture(build),
    ...practical(build),
    ...resource(build),
    ...login(build),
  }),
});

export default api;
