const baseUrl = 'rest/';
const serviceName = 'client_HomePageService';

export default (build) => ({
  getresourcelist: build.query({
    query: () => ({
      url: `/v1/edu/rs/list`,
      method: 'get',
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
  // 资源中心详情页接口
  getresourcedetails: build.query({
    query: (id) => ({
      url: `/v1/edu/rs/detail?ResourceId=${id}`,
      method: 'get',
    }),
    // providesTags: (result, error, id) => [{ type, id }],
  }),
});
